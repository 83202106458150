<template>
  <div id="productBOMContainer" v-if="isPermission && isEditData">
    <CAlert id="productBOMAlert" color="success" v-if="isSuccess === true">
      {{ $t("createProductSuccess") }}
    </CAlert>

    <div class="row">
      <div class="col-lg-10 col-md-10 col-sm-9 col-9">
        <h6 class="font-weight-normal">
          {{ $t("productTypeBOM") }} ({{ addlist.length }})
        </h6>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-3 col-3 text-right" v-if="isEditData" >
        <CButton  block color="primary" variant="outline"  @click="addProductBOM()"  >
          {{ $t("editBOM") }}
        </CButton>
      </div>
    </div>
    <hr />
    <CRow>
      <CCol sm="12" lg="12" md="12">
        <div >
          <table class="table table-hover border" style=" width: 100%" >
            <thead>
              <th class="font-weight-normal text-dark" style="width: 7%">
                {{ $t("img") }}
              </th>
              <!-- <th class="font-weight-normal text-dark">{{ $t('pluCode') }}</th> -->
              <th class="font-weight-normal text-dark" style="width: 40%">
                {{ $t("productName") }}
              </th>
              <th
                style="width: 20%"
                class="text-left font-weight-normal text-dark"
              >
                {{ $t("ratioCount") }}
              </th>
              <th
                class="font-weight-normal text-dark text-left"
                style="width: 20%"
              >
                {{ $t("quantityCount") }}
              </th>
              <th
                style="width: 10%"
                class="text-right font-weight-normal text-dark"
              ></th>
            </thead>
            <tbody v-if="loadingButton == true">
              <tr
                v-for="(item, index) in addlist"
                :key="index"
                hover style=" width: 100%"
                border
                striped
              >
                <td>
                  <div
                    :id="'productBOMItemsImage' + index"
                    v-if="
                      item.remoteImagePath !== undefined &&
                      item.remoteImagePath !== '' &&
                      item.remoteImagePath !== null
                    "
                    class="square-box"
                    :style="{
                      'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                      'background-size': 'cover',
                      'background-position': 'center center',
                      width: 'auto',
                      height: '40px',
                      'border-radius': '3px',
                      'aspect-ratio': '1.3',
                    }"
                  ></div>
                  <div
                    :id="'productBOMItemsColorBox' + index"
                    v-else
                    class="square-box"
                    :style="{
                      backgroundColor: item.colori,
                      width: 'auto',
                      'background-origin': 'content-box',
                      'border-radius': '3px',
                      height: '40px',
                      'aspect-ratio': '1.3',
                    }"
                  ></div>
                </td>
                <td class="font-weight-normal text-dark">
                  <div :id="'productBOMTableName' + index">
                    <span class="font-weight-normal text-dark">
                      {{ item.SKUName }} ({{ item.unitName }} /
                      {{ item.SKURatio }} )
                    </span>
                  </div>
                  <div :id="'productBOMTablePLU' + index">
                    <span class="font-weight-normal text-dark">
                      {{ item.productPLU.PLUCode }}
                    </span>
                  </div>
                  <div :id="'productBOMTableUnit' + index" class="row">
                    <div class="col-12 text-dark font-weight-normal">
                      <small class="text-dark font-weight-normal">
                        {{ $t("cost") }} : {{ item.stdCost }}</small
                      >
                    </div>
                    <!-- <div class="col-6">
                      <small class="text-dark font-weight-normal">
                        {{ item.unitName }} / {{ item.SKURatio }}
                      </small>
                    </div> -->
                  </div>
                </td>
                <td class="text-right font-weight-normal text-dark">
                  <input
                    :id="'productBOMTableRatioInput' + index"
                    type="number"
                    style="width: 100px"
                    min="0"
                    v-model.number="item.ratio"
                    class="form-control"
                    @input="calculate(item, 'quantity')"
                  />
                </td>
                <td class="text-right font-weight-normal text-dark">
                  <input
                    :id="'productBOMTableQuantityInput' + index"
                    style="width: 100px"
                    type="number"
                    min="0"
                    v-model.number="item.quantity"
                    class="form-control"
                    @input="calculate(item, 'ratio')"
                  />
                </td>
                <td>
                  <img
                    :id="'productBOMTableActionIcon' + index"
                    src="/img/trash.png"
                    style="width: 14px; height: 14px; cursor: pointer"
                    @click="conremoveRow(item, index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="loadingButton == false" class="text-center" width="100%">
          <button class="btn" type="button" disabled style="color: darkcyan">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            {{ $t("loading") }}
          </button>
        </div>
      </CCol>
    </CRow>
    <modal
      id="productBOMConfirmModal"
      color="success"
      :show.sync="confirmModal"
      :title="$t('confirmSaveImg')"
      v-on:isSave="saveProductBOM"
      centered
      :loading="loading"
    >
      <div id="productBOMConfirmModalContent">
        <h4 id="productBOMConfirmModalText" class="mt-4 mb-4 text-center">
          {{ $t("confirmSaveProduct") }}
        </h4>
      </div>
    </modal>

    <modal
      id="productBOMConfirmSaveChangeModal"
      color="success"
      :show.sync="confirmSaveChangeModal"
      :title="$t('confirmSaveImg')"
      v-on:isSave="saveProductBOM"
      centered
      :loading="loading"
    >
      <div id="productBOMConfirmSaveChangeModalContent">
        <h4
          id="productBOMConfirmSaveChangeModalText1"
          class="mt-4 mb-2 text-center"
        >
          {{ $t("confirmSaveChangeModal") }}
        </h4>
        <h4
          id="productBOMConfirmSaveChangeModalText2"
          class="mt-2 mb-2 text-center"
        >
          {{ $t("confirmSaveChangeModal2") }}
        </h4>
        <h4
          id="productBOMConfirmSaveChangeModalText3"
          class="mt-2 mb-2 text-center"
        >
          {{ $t("confirmSaveChangeModal3") }}
        </h4>
        <h4
          id="productBOMConfirmSaveChangeModalText4"
          class="mt-2 mb-4 text-center"
        >
          {{ $t("confirmSaveChangeModal4") }}
        </h4>
      </div>
    </modal>

    <CModal size="xl" :show.sync="addProductBOMModal">
      <CRow id="productBOMRow" style="padding: 0rem">
        <CCol id="productBOMColLeft" sm="6" lg="6" md="6">
          <CCard id="productBOMCardLeft" class="cardadd">
            <CCardBody id="productBOMCardBodyLeft" >
              <h5 id="productBOMTitleLeft">{{ $t("addBom") }}</h5>
              <div id="productBOMSearchWrapper">
                <CInput
                  id="productBOMSearchInput"
                  v-model.trim="keyword"
                  :placeholder="$t('searchByProduct')"
                  @input="getSKUbyType()"
                />
              </div>
              <table id="productBOMItemsWrapper" class="table table-hover"  >
                <tbody>
                  <div class="body-bom" style = "overflow-y: auto; ">
                  <CRow style=" width: 100%"
                    id="productBOMItemsRow"
                    v-for="(item, index) in items"
                    :key="index"
                  >
                    <CCol :id="'productBOMItemsCol' + index" col="12" lg="12">
                      <CCard
                        :id="'productBOMItemsCard' + index"
                        class="addon-body table"
                        @click="addBOMtoLits(item, index)"
                      >
                        <CCardBody
                          :id="'productBOMItemsCardBody' + index"
                          style="padding: 0.2rem"
                        >
                          <tr
                            :id="'productBOMItemsRowData' + index"
                            style="border-color: #ffffff"
                          >
                            <td
                              :id="'productBOMItemsImageWrapper' + index"
                              style="width: 15%"
                              class="td-tc"
                            >
                              <div
                                :id="'productBOMItemsImage' + index"
                                v-if="
                                  item.remoteImagePath !== undefined &&
                                  item.remoteImagePath !== '' &&
                                  item.remoteImagePath !== null
                                "
                                class="square-box"
                                :style="{
                                  'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                                  'background-size': 'cover',
                                  'background-position': 'center center',
                                  width: 'auto',
                                  height: '40px',
                                  'border-radius': '3px',
                                  'aspect-ratio': '1.3',
                                }"
                              ></div>
                              <div
                                :id="'productBOMItemsColorBox' + index"
                                v-else
                                class="square-box"
                                :style="{
                                  backgroundColor: item.colori,
                                  width: 'auto',
                                  'background-origin': 'content-box',
                                  'border-radius': '3px',
                                  height: '40px',
                                  'aspect-ratio': '1.3',
                                }"
                              ></div>
                            </td>
                            <td
                              :id="'productBOMItemsDetails' + index"
                              class="td-tc"
                            >
                              <tr :id="'productBOMItemsName' + index">
                                <span class="text-dark font-weight-normal">
                                  {{ item.SKUName }} ( {{ item.unitName }} /
                                  {{ item.SKURatio }})
                                </span>
                              </tr>
                              <tr :id="'productBOMItemsPLU' + index">
                                <span class="text-dark font-weight-normal">
                                  {{ item.productPLU.PLUCode }}
                                </span>
                              </tr>
                              <tr :id="'productBOMItemsUnit' + index" class="row">
                                <div class="col-12 text-dark font-weight-normal">
                                  <small class="text-dark font-weight-normal">
                                    {{ $t("cost") }} : {{ item.stdCost }}</small
                                  >
                                </div>
                              </tr>
                            </td>
                            <td
                              :id="'productBOMItemsAction' + index"
                              style="width: 2%"
                              class="text-right text-success td-tc"
                            >
                            <i class="fi fi-rr-angle-right"
                              @click="addBOMtoLits(item, index)"></i>
                              <!-- <img
                                :id="'productBOMItemsActionIcon' + index"
                                src="/img/mingcute--right-fill.png"
                                @click="addBOMtoLits(item, index)"
                              /> -->
                            </td>
                          </tr>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </div>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol id="productBOMColRight" sm="6" lg="6" md="6">
          <CCard
            id="productBOMCardRight"
            class="cardadd"
            style="background-color: #f6f6f6"
          >
            <CCardBody id="productBOMCardBodyRight" style="padding: 0.5rem">
              <h5 id="productBOMTitleRight" class="font-weight-normal">
                {{ $t("activeMat") }} ({{ countAddList }})
              </h5>
              <br />
              <div id="productBOMActiveList">
                <div
                  id="productBOMActiveListWrapper"
                  v-if="loadingButton"
               
                >
                  <table
                    id="productBOMTable"
                    class="table table-hover"
                    style="width: 100%"
                    v-if="addlist.length !== 0"
                  >
                    <thead id="productBOMTableHeader">
                      <tr id="productBOMTableHeaderRow">
                        <!-- <th
                          id="productBOMTableHeaderImage"
                          class="text-dark"
                          style="width: 7%"
                        ></th> -->
                        <th
                          id="productBOMTableHeaderName"
                          class="text-dark font-weight-normal"
                        >{{$t('product')}}</th>
                        <!-- <th id="productBOMTableHeaderRatio" class="text-dark text-center font-weight-normal" style="width: 25%">
                        {{ $t("ratioCount") }}
                      </th>
                      <th id="productBOMTableHeaderQuantity" class="text-dark text-center font-weight-normal" style="width: 25%">
                        {{ $t("quantityCount") }}
                      </th> -->
                        <!-- <th
                          id="productBOMTableHeaderAction"
                          style="width: 8%"
                        ></th> -->
                      </tr>
                    </thead>
                    <tbody id="productBOMTableBody">
                      <div class="body-bom" style = "overflow-y: auto; ">
                        <template v-if="loadingButton == true">
                          <tr
                            id="productBOMTableRow"
                            v-for="(item, index) in addlist"
                            :key="index"
                            hover
                            border
                            striped
                          >
                            <td
                              :id="'productBOMTableImage' + index"
                              style="width: 7%"
                            >
                              <div
                                :id="'productBOMTableImageWrapper' + index"
                                v-if="
                                  item.remoteImagePath !== undefined &&
                                  item.remoteImagePath !== '' &&
                                  item.remoteImagePath !== null
                                "
                                class="square-box"
                                :style="{
                                  'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                                  'background-size': 'cover',
                                  'background-position': 'center center',
                                  width: 'auto',
                                  height: '40px',
                                  'border-radius': '3px',
                                  'aspect-ratio': '1.3',
                                }"
                              ></div>
                              <div
                                :id="'productBOMTableColorBox' + index"
                                v-else
                                class="square-box"
                                :style="{
                                  backgroundColor: item.colori,
                                  width: 'auto',
                                  'background-origin': 'content-box',
                                  'border-radius': '3px',
                                  height: '40px',
                                  'aspect-ratio': '1.3',
                                }"
                              ></div>
                            </td>
                            <td :id="'productBOMTableDetails' + index">
                              <div :id="'productBOMTableName' + index">
                                <span class="font-weight-normal text-dark">
                                  {{ item.SKUName }} ({{ item.unitName }} /
                                  {{ item.SKURatio }})
                                </span>
                              </div>
                              <div :id="'productBOMTablePLU' + index">
                                <span class="font-weight-normal text-dark">
                                  {{ item.productPLU.PLUCode }}
                                </span>
                              </div>
                              <div
                                :id="'productBOMTableUnit' + index"
                                class="row"
                              >
                                <div class="col-12 text-dark font-weight-normal">
                                  <small class="text-dark font-weight-normal">
                                    {{ $t("cost") }} : {{ item.stdCost }}</small
                                  >
                                </div>
                                <!-- <div class="col-6">
                                  <small class="text-dark font-weight-normal">
                                  
                                </small>
                              </div> -->
                              </div>
                            </td>
                            <!-- <td :id="'productBOMTableRatio'+ index" style="width: 25%">
                            <input
                              :id="'productBOMTableRatioInput'+ index"
                              type="number"
                              style="width: 100px;"
                              min="0"
                              v-model.number="item.ratio"
                              class="form-control"
                              @input="calculate(item, 'quantity')"
                            />
                          </td>
                          <td :id="'productBOMTableQuantity'+ index" class="text-center" style="width: 25%">
                            <input
                              :id="'productBOMTableQuantityInput'+ index"
                              style="width: 100px;"
                              type="number"
                              min="0"
                              v-model.number="item.quantity"
                              class="form-control"
                              @input="calculate(item, 'ratio')"
                            />
                          </td> -->

                            <td
                              :id="'productBOMTableAction' + index"
                              style="width: 8%"
                            >
                              <img
                                :id="'productBOMTableActionIcon' + index"
                                src="/img/trash.png"
                                style="width: 14px; height: 14px; cursor: pointer"
                                @click="conremoveRow(item, index)"
                              />
                            </td>
                          </tr>
                        </template>
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template #footer>
        <div class="w-100 d-flex justify-content-center">
          <CButton
            style="width: 100%; margin-left: 1cap"
            @click="addProductBOMModal = false"
            color="light"
          >
            <b>{{ $t("closed") }}</b>
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
  <div id="productBOMNoPermission" v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import util from "@/util/util";
import { mapGetters } from "vuex";
import local from "@/services/local";
import permis from "@/util/permission";

export default {
  components: {},
  data() {
    return {
      loading: true,
      loadingButton: true,
      isSuccess: false,
      SKUName: "",
      keyword: "",
      data: [],
      BOM: [],
      addlist: [],
      dataSKUbytype: [],
      validateShop: false,
      quantity: 1,
      ratio: 1,
      countAddList: 0,
      shop: this.$store.getters.shopObjectId,
      confirmModal: false,
      isFromBOM: false,
      originalBOM: [],
      confirmSaveChangeModal: false,
      countBom: 0,
      addProductBOMModal: false,
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date"]),
    isPermission() {
      let path = "/product";
      return permis.findPermissionRead("product", path);
    },
    isEditData() {
      let path = "/product";
      return permis.findPermissionEdit("product", path);
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    gencolor(item) {
      let color = util.generateColor(item);
      return color;
    },
    items() {
      let detail = [];
      let data = this.dataSKUbytype;
      for (let i = 0; i < data.length; i++) {
        let SKURatio = "";
        if (data[i].ProductPLU.SKURatio != undefined) {
          SKURatio = data[i].ProductPLU.SKURatio;
        }
        let unit = "";
        let unitName = "";
        let SKUName = "";
        let stdCost = 0;
        if (data[i].unit === undefined) {
          unit = "-";
          unitName = "-";
        } else {
          if (data[i].unit.name === undefined) {
            unit = "-";
            unitName = "-";
          } else {
            unit = data[i].unit;
            unitName = data[i].unit.name;
          }
        }

        if (data[i].stdCost !== undefined) {
          stdCost = data[i].stdCost;
        }

        if (data[i].ProductPLU.name != undefined) {
          SKUName = data[i].ProductPLU.name;
        }
        detail.push({
          remoteImagePath: data[i].remoteImagePath,
          SKUName: SKUName,
          unit: unit,
          id: data[i].id,
          objectId: data[i].objectId,
          productPLU: data[i].ProductPLU,
          SKURatio: SKURatio,
          unitName: unitName,
          colori: util.generateColor(data[i].indexColor),
          stdCost: stdCost,
        });
      }
      return detail;
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE;
    },
  },
  created() {
    this.getProduct();
    this.getBOM();
    this.getSKUbyType();
    this.shop = this.shops.find((i) => i.objectId === this.shop);
  },
  mounted() {
    document.addEventListener("keydown", this.doSave);
  },
  methods: {
    addProductBOM() {
      this.addProductBOMModal = true;
    },
    formatNumber(value) {
      if (value % 1 === 0) {
        // เป็นจำนวนเต็ม
        return value.toString();
      } else {
        // มีจุดทศนิยม
        return value.toFixed(4).replace(/\.?0+$/, "");
      }
    },
    hasDataChanged() {
      if (this.BOM.length !== this.addlist.length) {
        return true;
      }
      for (let i = 0; i < this.BOM.length; i++) {
        const original = this.BOM[i];
        const updated = this.addlist.find(
          (item) => item.productPLU.PLUCode === original.ProductPLU.PLUCode
        );
        if (
          !updated ||
          original.quantity !== parseFloat(updated.quantity) ||
          original.ratio !== parseFloat(updated.ratio)
        ) {
          return true;
        }
      }
      return false;
    },
    confirmSaveProductBOM() {
      if (this.hasDataChanged()) {
        this.showConfirmationModal();
      } else {
        this.loading = true;
        this.confirmModal = true;
      }
    },
    showConfirmationModal() {
      this.loading = true;
      this.confirmSaveChangeModal = true;
    },
    calculate(item, type) {
      item.quantity = parseFloat(item.quantity) || 0;
      item.ratio = parseFloat(item.ratio) || 0;

      if (type === "quantity" && item.ratio !== 0) {
        item.quantity = parseFloat((1 / item.ratio).toFixed(4));
      } else if (type === "ratio" && item.quantity !== 0) {
        item.ratio = parseFloat((1 / item.quantity).toFixed(4));
      }
    },
    doSave(e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return;
      } else if (e.keyCode === 83 && e.ctrlKey) {
        this.confirmSaveProductBOM();
      }
      e.preventDefault();
    },
    getBOM() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      let params = {
        skuObjectId: this.$route.params.productSKUObjectId,
      };
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        url: "/api/v1.0/" + uid + "/productsku/getbom",
        params: params,
        headers: headers,
        method: "GET",
      }).then((res) => {
        this.BOM = res.data.data;
        this.countBom = res.data.length;
        this.originalBOM = JSON.parse(JSON.stringify(this.BOM));
        this.BOM.forEach((e) => {
          let SKURatio = "";
          let SKUName = "";
          let unitName = "-";
          let stdCost = 0;
          if (e.ProductPLU.SKURatio != undefined) {
            SKURatio = e.ProductPLU.SKURatio;
          }
          if (e.ProductPLU.name != undefined) {
            SKUName = e.ProductPLU.name;
          }
          if (e.ProductPLU.unit != undefined) {
            if (e.ProductPLU.unit.name != undefined) {
              unitName = e.ProductPLU.unit.name;
            }
          }

          if (e.ProductSKU.stdCost !== undefined) {
            stdCost = e.ProductSKU.stdCost;
          }
          let formattedQuantity = e.quantity.toFixed(4);
          let formattedRatio = e.ratio.toFixed(4);
          this.addlist.push({
            productPLU: e.ProductPLU,
            id: e.id,
            SKUName: SKUName,
            unitName: unitName,
            objectId: e.objectId,
            unit: e.ProductPLU.unit,
            colori: util.generateColor(e.ProductPLU.indexColor),
            remoteImagePath: e.ProductPLU.remoteImagePath,
            SKURatio: SKURatio,
            quantity: formattedQuantity,
            ratio: formattedRatio,
            stdCost: stdCost,
          });
          this.countAddList = this.addlist.length;
        });
      });
    },
    getSKUbyType() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        shopObjectId: shopObjectId,
        skuType: "P",
        keyword: this.keyword,
        page: 1,
        limit: 50,
      };
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        url: "/api/v1.0/" + uid + "/productsku/getbytype",
        params: params,
        headers: headers,
        method: "GET",
      }).then((res) => {
        this.dataSKUbytype = res.data.data;
        this.BOM.forEach((e) => {
          let data = res.data.data;
          const index = data.findIndex(
            (x) => x.ProductPLU.objectId === e.ProductPLU.objectId
          );
          if (index != -1) {
            this.dataSKUbytype.splice(index, 1);
          }
        });
      });
    },
    addBOMtoLits(item, index) {
      this.addlist.push({
        productPLU: item.productPLU,
        id: item.id,
        SKUName: item.SKUName,
        objectId: item.productPLU.objectId,
        unit: item.unit,
        unitName: item.unitName,
        colori: item.colori,
        quantity: 1,
        ratio: 1,
        remoteImagePath: item.remoteImagePath,
        SKURatio: item.SKURatio,
        stdCost: item.stdCost,
      });
      this.items.splice(index, 1);
      this.countAddList = this.addlist.length;
    },
    conremoveRow: function (item, index) {
      var con = confirm(this.$i18n.t("confirmDeleteProduct"));
      if (con == true) {
        this.addlist.splice(index, 1);
        this.items.push(item);
        this.countAddList = this.addlist.length;
        this.removeRow(item);
      }
    },
    removeRow: function (item) {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const plan = this.users.currentPlan;
      const shopObjectId = this.shopObjectId;
      let data = {
        shopObjectId: shopObjectId,
        objectId: item.objectId,
      };
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        method: "post",
        url: "/api/v1.0/" + uid + "/ProductBOM/softdelete",
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.countAddList = this.addlist.length;
          }, 1000)
        )
        .catch(function (error) {
          console.log(error);
        });
    },
    saveProductBOM() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      let data = this.addlist;
      let dataitem = [];
      this.loading = false;
      data.forEach((e) => {
        const unit = e.unit;
        if (unit === undefined) {
          dataitem.push({
            PLUCode: e.productPLU.PLUCode,
            id: e.productPLU.id,
            name: e.productPLU.name,
            objectId: e.productPLU.objectId,
            quantity: parseFloat(e.quantity),
            ratio: parseFloat(e.ratio),
          });
        } else {
          dataitem.push({
            PLUCode: e.productPLU.PLUCode,
            id: e.productPLU.id,
            name: e.productPLU.name,
            objectId: e.productPLU.objectId,
            unit: {
              id: e.unit.id,
              name: e.unit.name,
              objectId: e.unit.objectId,
            },
            quantity: parseFloat(e.quantity),
            ratio: parseFloat(e.ratio),
          });
        }
      });
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        method: "post",
        url: "/api/v1.0/" + uid + "/productsku/addbom",
        data: {
          objectId: this.$route.params.productSKUObjectId,
          BOM: dataitem,
        },
        headers: headers,
      })
        .then(() => {
          setTimeout(() => {
            this.loading = true;
            this.confirmModal = false;
            this.confirmSaveChangeModal = false;
            // this.$router.go(-1);
          }, 1000);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getProduct() {
      this.loadingButton = false;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: "",
      };
      let data = {};
      this.loading = false;
      local
        .get(
          "/api/v1.0/" +
            uid +
            "/ProductSKU/data/" +
            this.$route.params.productSKUObjectId,
          {
            params,
          }
        )
        .then((res) => {
          this.data = res.data.data.document;
          data = this.data;
          this.SKUName = data.SKUName;
          this.loadingButton = true;
          this.searchLoadingButton = true;
        });
    },
    getback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.met-body {
  border: 0.5px solid #f6f6f6;
  border-radius: 5px;
  padding: 1rem;
  /* min-height: 120px; */
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #29b289;
  background-color: #29b289;
}

.cursor {
  cursor: pointer;
}

.limit-2 {
  line-height: 20px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cardadd {
  min-height: 500px;
  max-height: 500px;
  /* border-color: #3abc98; */
  border-color: rgb(218, 216, 216);
}

.form-control {
  cursor: pointer;
}

.custom-select {
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: default;
}

.cursor {
  cursor: pointer;
}

td.td-tc {
  border-top-color: white;
}

.body-bom {
  max-height: 400px;
}

.addon-body {
  border: 1px solid #29b289;
  border-radius: 5px;
  padding: 0rem;
  /* min-height: 120px; */
}

.addon-body-active {
  border: 2px solid #f6f6f6;
  box-shadow: rgba(0, 0, 21, 0.075) 0px 0.125rem 0.25rem;
  border-radius: 5px;
  padding: 0rem;
  /* min-height: 120px; */
}

@media (min-height: 400px) {
  .body-bom {
    max-height: 320px;
  }

  .cardadd {
    min-height: 400px;
    max-height: 400px;
    /* border-colo #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}
@media (min-height: 600px) {
  .body-bom {
    max-height: 380px;
  }

  .cardadd {
    min-height: 500px;
    max-height: 500px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}
@media (min-height: 700px) {
  .body-bom {
    max-height: 400px;
  }

  .cardadd {
    min-height: 520px;
    max-height: 520px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}

@media (min-height: 900px) {
  .body-bom {
    max-height: 520px;
  }

  .cardadd {
    min-height: 640px;
    max-height: 640px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}
</style>
